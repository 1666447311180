(function() {
    'use strict';

    angular.module('ionicPlaylistVideo', [
        'ionic',
        'ionicAlert',
        'ionicComment',
        'ionicLogin',
        'ionicPlaylist',
        'uabDefaultImage',
        'uabDefaultVariable',
        'uabRedirect'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicPlaylistVideo').service(
        'PlaylistVideoListService',
        PlaylistVideoListService
    );

    PlaylistVideoListService.$inject = [
        'DefaultVariableService',
        'PlaylistService',
        'RedirectService'
    ];

    function PlaylistVideoListService(
        DefaultVariableService,
        PlaylistService,
        RedirectService
    ) {
        var PlaylistVideoListService = this;

        PlaylistVideoListService.loadPlaylist = loadPlaylist;
        function loadPlaylist(playlist) {
            playlist = DefaultVariableService.getObject(
                playlist
            );

            return PlaylistService.getPlaylist(playlist);
        }

        PlaylistVideoListService.selectVideo = selectVideo;
        function selectVideo(sref, playlist, video) {
            return RedirectService.goTo(
                sref,
                {
                    playlistId: playlist.id,
                    videoId:    video.id
                }
            );
        }

        PlaylistVideoListService.reset = reset;
        function reset() {

        }

        PlaylistVideoListService.reset();

        return PlaylistVideoListService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicPlaylistVideo').controller(
        'PlaylistVideoListController',
        PlaylistVideoListController
    );

    PlaylistVideoListController.$inject = [
        'DefaultImageService',
        'DefaultVariableService',
        'PlaylistVideoListService',
        'LoginService',
        '$scope'
    ];

    function PlaylistVideoListController(
        DefaultImageService,
        DefaultVariableService,
        PlaylistVideoListService,
        LoginService,
        $scope
    ) {
        var PlaylistVideoListController = this;

        PlaylistVideoListController.playlist = DefaultVariableService.getObject();

        $scope.$watch(
            'onExpand',
            function(onExpand) {
                PlaylistVideoListController.onExpand = DefaultVariableService.getPromise(
                    onExpand
                );
            }
        );

        $scope.$watch(
            'playlist',
            function(playlist) {
                PlaylistVideoListController.setPlaylist(playlist);
            }
        );

        $scope.$watch(
            'showControls',
            function(showControls) {
                PlaylistVideoListController.showControls = DefaultVariableService.get(
                    showControls,
                    true
                );
            }
        );

        $scope.$watch(
            'showIfEmpty',
            function(showIfEmpty) {
                PlaylistVideoListController.showIfEmpty = DefaultVariableService.get(
                    showIfEmpty,
                    false
                );
            }
        );

        $scope.$watch(
            'video',
            function(video) {
                PlaylistVideoListController.setVideo(video);
            }
        );

        PlaylistVideoListController.getVideoImage = getVideoImage;
        function getVideoImage(video) {
            return DefaultImageService.getVideoImage(video);
        }

        PlaylistVideoListController.selectVideo = selectVideo;
        function selectVideo(video) {
            return PlaylistVideoListService.selectVideo(
                PlaylistVideoListController.sref,
                PlaylistVideoListController.playlist,
                video
            );
        }

        PlaylistVideoListController.setControls = setControls;
        function setControls() {
            var videos = DefaultVariableService.getArray(
                PlaylistVideoListController.playlist.videos
            );

            if (videos.length !== 0) {
                var playlistLength = videos.length;

                for (var i = playlistLength - 1; i >= 0; i--) {
                    var video = DefaultVariableService.getObject(
                        PlaylistVideoListController.playlist.videos[i]
                    );

                    if (video.id === PlaylistVideoListController.video.id) {
                        PlaylistVideoListController.previousVideo = (i === 0) ?
                            DefaultVariableService.getObject() : PlaylistVideoListController.playlist.videos[i - 1];

                        PlaylistVideoListController.nextVideo = (i === playlistLength - 1) ?
                            DefaultVariableService.getObject() : PlaylistVideoListController.playlist.videos[i + 1];

                        break;
                    }
                }
            }
        }

        PlaylistVideoListController.setPlaylist = setPlaylist;
        function setPlaylist(playlist) {
            PlaylistVideoListController.isLoadingPlaylist = true;

            return PlaylistVideoListService.loadPlaylist(playlist).then(
                function(playlist) {
                    if (playlist) {
                        PlaylistVideoListController.playlist = DefaultVariableService.getObject(
                            playlist
                        );

                        PlaylistVideoListController.playlist.videos = DefaultVariableService.getArray(
                            PlaylistVideoListController.playlist.videos
                        );

                        PlaylistVideoListController.setControls();
                    }

                    return playlist;
                }
            ).finally(
                function() {
                    PlaylistVideoListController.isLoadingPlaylist = false;
                }
            );
        }

        PlaylistVideoListController.setVideo = setVideo;
        function setVideo(video) {
            PlaylistVideoListController.video = DefaultVariableService.getObject(
                video
            );

            PlaylistVideoListController.setControls();
        }

        PlaylistVideoListController.toggleVideoList = toggleVideoList;
        function toggleVideoList() {
            PlaylistVideoListController.expandVideoList = DefaultVariableService.toggle(
                PlaylistVideoListController.expandVideoList,
                false
            );

            PlaylistVideoListController.onExpand(
                PlaylistVideoListController.expandVideoList
            );
        }

        PlaylistVideoListController.reset = reset;
        function reset() {
            PlaylistVideoListController.expandVideoList = false;

            PlaylistVideoListController.isLoadingPlaylist = true;

            PlaylistVideoListController.isLoadingVideo = true;

            PlaylistVideoListController.nextVideo = DefaultVariableService.getObject();

            PlaylistVideoListController.previousVideo = DefaultVariableService.getObject();

            PlaylistVideoListController.sref = DefaultVariableService.get(
                PlaylistVideoListController.sref,
                'app.video'
            );
        }

        PlaylistVideoListController.init = init;
        function init() {
            PlaylistVideoListController.reset();
        }

        LoginService.register(PlaylistVideoListController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicPlaylistVideo').directive('ionicPlaylistVideoList', ionicPlaylistVideoList);

    function ionicPlaylistVideoList() {
        return {
            bindToController: {
                sref:     '@'
            },
            controller:   'PlaylistVideoListController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                playlist:     '=',
                onExpand:     '=',
                showControls: '=',
                showIfEmpty:  '=',
                video:        '='
            },
            template:'<div data-ng-if="ctrl.showIfEmpty || ctrl.playlist.videos.length !== 0"><div class="button-bar" data-ng-if="ctrl.showControls"><button data-ng-disabled="ctrl.previousVideo.id === 0" class="col col-50 button decline-color" data-ng-click="ctrl.selectVideo(ctrl.previousVideo)"><i class="icon ion-ios-skipbackward"></i> PREVIOUS VIDEO</button> <button data-ng-disabled="ctrl.nextVideo.id === 0" class="col col-50 button accept-color" data-ng-click="ctrl.selectVideo(ctrl.nextVideo)">NEXT VIDEO <i class="icon ion-ios-skipforward"></i></button></div><ion-list data-ng-if="!ctrl.isLoadingPlaylist && ctrl.playlist.length !== 0"><div class="item item-divider item-icon-right playlist-video-list-divider" data-ng-click="ctrl.toggleVideoList()"><h2>PLAYLIST: {{ ctrl.playlist.title }}</h2><h4>{{ ctrl.playlist.videos.length }} VIDEOS</h4><i data-ng-if="!ctrl.expandVideoList" class="icon ion-plus-round"></i> <i data-ng-if="ctrl.expandVideoList" class="icon ion-minus-round"></i></div><a data-ng-if="ctrl.expandVideoList" class="item item-thumbnail-left ionic-playlist-video" data-ng-class="{ \'ionic-playlist-selected-video\': video.id === ctrl.video.id }" data-ng-repeat="video in ctrl.playlist.videos" data-ng-click="ctrl.selectVideo(video)"><img data-ng-class="{ \'animated infinite pulse\': video.id === ctrl.video.id }" data-ng-src="{{ ctrl.getVideoImage(video.thumbnail.file_path) }}"><h3 class="decline-color" data-ng-if="video.id === ctrl.video.id">CURRENTLY WATCHING...</h3><h2>{{ video.title }}</h2><div data-ng-if="video.description && video.description.length !== 0"><p>{{ video.description | limitTo : 50 }}...</p></div></a><div data-ng-if="ctrl.expandVideoList" class="item item-divider playlist-video-list-divider"></div></ion-list></div>'
        };
    }
})();